
// Social Work
main.social-work {
  section.page {
    @extend .mosaic-bg;
    .mask {
        background-size: cover;
        display: table;
      &:before {
        display: block;
      }
      .center {
        @extend .bg;

          background: none;
          position: relative;
          display: table-cell;
          vertical-align: middle;

      }
    }
    h1 {
      color: $blue;
      text-transform: uppercase;
      margin: 0;
      text-align: center;
      padding: 23px 0 22px;
      @media (min-width: $screen-md-min) {
        color: $white;
      }
    }
    .featured {
      margin: 35px 0 25px 0;

      @media (min-width: $screen-md-min) {
        margin: 90px 0;
      }
      img {
        max-width: 300px;
        @media (min-width: $screen-md-min) {
          max-width: none;
        }
      }
      .featured-content {
        background-color: #f4f5f6;
        box-shadow: 0 0 5px #b7b7b7;
        position: relative;
        z-index: 2;
        padding: 25px 35px;
        text-align: center;
        @media (min-width: $screen-md-min) {
          text-align: left;
          padding: 0;
        }
      }
      .title {
        @extend .ultlt;
        font-size: 24px;
        line-height: 28px;
        margin: 45px 0 13px;
        @media (min-width: $screen-md-min) {
          font-size: 35px;
          line-height: 38px;
        }
      }
      .text {
        @media (min-width: $screen-md-min) {
          margin-bottom: 50px;
        }
      }
      .book-btn {
        display: none;
        padding: 14px 26px 11px;
        @media (min-width: $screen-md-min) {
          display: inline-block;
        }
      }
    }
    .companies {
      margin-bottom: 12px;
      margin-top: 80px;
      @media (min-width: $screen-md-min) {
        margin-bottom: 130px;
      }
      .companies-container {
        position: relative;
        z-index: 2;
        background-color: $white-smooth;
        box-shadow: 0 0 5px lighten($gray-light, 25%);
        padding: 25px 35px;
        text-align: center;
        @media (min-width: $screen-md-min) {
          text-align: left;
          padding: 40px 65px;
        }
        .text {
          margin: 35px 0 35px;
          color: $blue;
          font-size: 14px;
          text-align: center;
        }
        .company {
          margin-top: 25px;
          @media (min-width: $screen-md-min) {
            margin-top: 60px;
          }
          &:first-of-type {
            margin-top: 0;
          }
          .row {
            @media (min-width: $screen-md-min) {
              font-size: 0;
            }
            > div {
              @media (min-width: $screen-md-min) {
                display: inline-block;
                vertical-align: middle;
                float: none;
              }
            }
          }
        }
        a {
          @extend .bdcn;
          color: $white;
          background-color: $red;
          text-transform: uppercase;
          padding: 15px 28px 11px;
          display: inline-block;
          font-size: 12px;
        }
      }
    }
    .book-btn {
      @media (min-width: $screen-md-min) {
        display: none;
        font-size: 12px;
      }
    }
  }
}