
// Contact
main.contact {
  @extend .mosaic-bg;
  h1 {
    color: $blue;
    text-transform: uppercase;
    margin: 0;
    text-align: center;
    padding: 23px 0 22px;
    @extend .bg;
    @media (min-width: $screen-md-min) {
      display: none;
    }
  }
  h5 {
    @extend .bdcn;
    color: $blue;
    font-size: 16px;
    margin: 15px 0 20px;
  }
  section.form {
    @media (min-width: $screen-md-min) {
      padding-top: 60px;
    }
    .container {
      margin-top: 16px;
    }
    .form-container {
      position: relative;
      z-index: 2;
      box-shadow: 0 0 5px lighten($gray-light, 25%);
      background-color: $white-smooth;
      padding: 15px 20px;
      @media (min-width: $screen-md-min) {
        padding: 30px 65px;
      }
    }
    .row {
      > div {
        &:nth-child(2) {
          display: none;
          padding-left: 130px;
          @media (min-width: $screen-md-min) {
            display: block;
          }
        }
      }
    }
    form {
      @media (min-width: $screen-md-min) {
        padding-right: 90px;
      }
    }
    input,
    textarea {
      @extend .bdcn;
      border-color: #b0b0b2;
      font-size: 12px;
      background-color: $white-smooth;
    }
    input {
      height: 40px;
    }
    button[type="submit"] {
      color: $white;
      background-color: $blue;
      border: 0;
      @extend .bdcn;
      margin-top: 25px;
      padding: 14px 30px 10px;
      font-size: 12px;
      &:hover {
        background-color: $blue-hover;
      }
    }
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: $blue;
      font-size: 12px;
      text-transform: uppercase;
    }
    ::-moz-placeholder { /* Firefox 19+ */
      color: $blue;
      font-size: 12px;
      text-transform: uppercase;
    }
    :-ms-input-placeholder { /* IE 10+ */
      color: $blue;
      font-size: 12px;
      text-transform: uppercase;
    }
    :-moz-placeholder { /* Firefox 18- */
      color: $blue;
      font-size: 12px;
      text-transform: uppercase;
    }
    .info {
      font-size: 18px;
      address {
        margin: 0;
      }
      a {
        display: inline-block;
      }
      .phone {
        @extend .bd;
      }
      .email {
        margin: 40px 0 50px;
      }
    }
    .social {
      a {
        margin-right: 15px;
      }
    }
  }
  .book-btn {
    @media (min-width: $screen-md-min) {
      display: none;
    }
  }
}

section.map-tripadvisor {
  margin-top: 44px;
  @media (min-width: $screen-md-min) {
    padding-bottom: 90px;
  }
  h5 {
    margin: 0;
    padding: 7px 7px 11px;
    color: $blue;
    font-size: 16px;
    @extend .bdcn;
    @media (min-width: $screen-md-min) {
      padding: 13px 14px 23px;
    }
  }
  > .container {
    > .row {
      @media (min-width: $screen-md-min) {
        display: table;
      }
      > div {
        margin-bottom: 44px;
        @media (min-width: $screen-md-min) {
          display: table-cell;
          width: 50%;
          float: none;
        }
        &:nth-child(2) {
          .featured-header {
            @media (min-width: $screen-md-min) {
              position: absolute;
              top: 0px;
              left: 16px;
              right: 16px;
            }
          }
          .featured-footer {
            @media (min-width: $screen-md-min) {
              position: absolute;
              bottom: 0px;
              left: 16px;
              right: 16px;
            }
          }
        }
      }
    }
  }
  .map-container {
    position: relative;
    z-index: 2;
    background-color: $white-smooth;
    padding: 7px;
    box-shadow: 0 0 5px lighten($gray-light, 25%);
    @media (min-width: $screen-md-min) {
      padding: 14px;
    }
    .row {
      color: $blue;
      padding: 13px 7px;
      @media (min-width: $screen-md-min) {
        padding: 25px 0 11px;
      }
      a {
        @extend .bdcn;
        font-size: 12px;
        border: 1px solid $blue;
        padding: 19px 44px 15px;
        display: inline-block;
        &:hover {
          color: $white;
          background-color: $white-hover;
        }
        @media (min-width: $screen-md-min) {
          padding: 19px 27px 15px;
        }
        @media (min-width: $screen-lg-min) {
          padding: 19px 52px 15px;
        }
      }
    }
  }
  .map {
    display: block;
    background-image: url(#{$img-path}/map.jpg);
    width: 100%;
    height: 195px;
    background-size: cover;
    border: 1px solid $blue;
    @media (min-width: $screen-sm-min) {
      height: 390px;
    }
    @media (min-width: $screen-md-min) {
      background-position: 30%;
    }
    @media (min-width: $screen-lg-min) {
      background-position: 15%;
    }
  }
  address {
    margin-bottom: 13px;
    @media (min-width: $screen-md-min) {
      font-size: 20px;
      margin-bottom: 0;
    }
  }
  .tripadvisor-container {
    position: relative;
    z-index: 2;
    background-color: $white-smooth;
    padding: 7px;
    box-shadow: 0 0 5px lighten($gray-light, 25%);
    @media (min-width: $screen-md-min) {
      padding: 20px 40px;
      position: absolute;
      top: 10px;
      bottom: 10px;
      left: 15px;
      right: 15px;
    }
    #CDSWIDSSP {
      width: 100% !important;
      .widSSPData {
        background-color: transparent !important;
      }
    }
  }
}
