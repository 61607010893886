
// Contact
#cookies-bar {
  display: none;
  position: fixed;
  background-color: $blue;
  color: $white;
  bottom: 0;
  z-index: 999;
  width: 100%;
  font-size: 12px;
  h5 {
    font-size: 12px;
    @extend .bd;
    margin: 20px 0 5px;
  }
  a {
    font-size: 12px;
    &.ok {
      color: $white;
      border: 1px solid $white;
      text-transform: uppercase;
      padding: 8px 30px;
      display: inline-block;
      margin-bottom: 20px;
      @media (min-width: $screen-sm-min) {
        position: absolute;
        top: 16px;
        right: 15px;
      }
      &:hover {
        color: #e7e7e7;
        border: 1px solid #e7e7e7;
      }
    }
  }
  p {
    color: #e7e7e7;
    a {
      color: #e7e7e7;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .container {
    position: relative;
  }
}