
// Wello Family
main.wello-family {
  @extend .bg;
  section.members {
    padding: 20px 0 15px;
    text-align: center;
    @media (min-width: $screen-md-min) {
      padding-top: 0;
    }
    .mask {
      background-size: 0;
      @media (min-width: $screen-md-min) {
        background-size: cover;
        display: table;
        width: 100%;
        text-align: center;
      }
      &:before {
        display: none;
        @media (min-width: $screen-md-min) {
          display: block;
        }
      }
      .center {
        @media (min-width: $screen-md-min) {
          position: relative;
          display: table-cell;
          vertical-align: middle;
          color: $white;
        }
        > div {
          @media (min-width: $screen-md-min) {
            width: 500px;
            margin: auto;
          }
        }
        .text {
          display: none;
          line-height: 26px;
          @media (min-width: $screen-md-min) {
            display: block;
          }
        }
      }
    }
    h1 {
      color: $blue;
      text-transform: uppercase;
      margin-left: 60px;
      margin-right: 60px;
      @media (min-width: $screen-md-min) {
        color: $white;
        margin: 0 0 60px;
      }
    }
    .swiper-container {
      width: 100%;
      @media (min-width: $screen-md-min) {
        display: none;
      }
    }
    .swiper-button-next, .swiper-button-prev {
      display: none;
    }
    .swiper-slide {
      padding: 10px 60px;
      img {
        box-shadow: 10px 10px 30px #a9a9a9;
      }
    }
    .swiper-pagination-bullet {
      background-color: #acb2b8;
      opacity: 1;
    }
    .swiper-pagination-bullet-active {
      background-color: #445262;
    }
    .swiper-pagination {
      bottom: 0;
    }
    .name {
      @extend .md;
      color: $blue;
      font-size: 15px;
      letter-spacing: 2px;
      text-transform: none;
      margin-top: 30px;
      @media (min-width: $screen-md-min) {
        font-size: 30px;
      }
    }
    .position {
      @extend .md;
      text-transform: none;
    }
    .text {
      margin: 10px 0 20px;
    }
    .row {
      display: none;
      font-size: 0;
      padding: 50px 0;
      @media (min-width: $screen-md-min) {
        display: block;
      }
      > div {
        display: inline-block;
        vertical-align: top;
        float: none;
        .member {
          margin: 0 20px 55px 20px;
          &:nth-child(even) {
            text-align: right;
          }
          > div {
            display: inline-block;
          }
        }
        &:first-of-type {
          margin-top: 95px;
        }
        .position {
          font-size: 14px;
        }
        .name {
          margin-top: 40px;
          text-align: center;
        }
        .position {
          text-align: center;
        }
        .text {
          font-size: 14px;
          width: 225px;
          margin: 15px auto 20px;
          text-align: center;
        }
        img {
          box-shadow: 10px 10px 30px $gray-light;
        }
      }
    }
  }
  .book-btn {
    @media (min-width: $screen-md-min) {
      display: none;
    }
  }
}