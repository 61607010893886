
// Apartments
main.apartments {
  @extend .mosaic-bg;
  section.types {
    text-align: center;
    h1 {
      color: $white;
      margin: 25px 0;
      @media (min-width: $screen-md-min) {
        margin: 60px auto;
        width: 85%;
      }
    }
    .container {
      position: relative;
    }
    ul {
      margin: 0 10px 45px;
    }
    li {
      border: 1px solid #afaeaf;
      font-size: 15px;
      margin-bottom: 18px;
      @media (min-width: $screen-md-min) {
        display: inline-block;
        vertical-align: top;
        margin: 0 15px 45px;
      }
      @media (min-width: $screen-lg-min) {
        margin: 0 30px 45px;
      }
      a {
        display: block;
        color: #afaeaf;
        height: 83px;
        display: table;
        width: 100%;
        @media (min-width: $screen-md-min) {
          width: 260px;
        }
        &.active {
          background-color: $blue;
        }
      }
      .center {
        display: table-cell;
        vertical-align: middle;
      }
      img {
        display: inline-block;
        vertical-align: middle;
      }
      .content {
        display: inline-block;
        vertical-align: middle;
        margin-left: 20px;
        span {
          @extend .thcn;
          text-transform: uppercase;
        }
        div {
          @extend .yellowtail;
          font-size: 34px;
          line-height: 22px;
        }
      }
    }
  }
  section.apartments-container {
    .presents {
      @media (min-width: $screen-md-min) {
        display: none;
      }
      .present {
        color: $white;
        background-color: $blue;
        padding: 14px 14px 22px 14px;
        box-shadow: 0 0 10px $gray-dark;
        margin-bottom: 11px;
        @media (min-width: $screen-md-min) {
          width: 714px;
          margin: 0 auto 20px;
          text-align: left;
          padding: 14px 60px 22px 60px;
        }
        &:last-of-type {
          @media (min-width: $screen-md-min) {
            margin-bottom: 0;
          }
        }
        img {
          float: left;
          width: 55px;
          @media (min-width: $screen-md-min) {
            width: 100px;
          }
        }
        h2 {
          @extend .ultlt;
          letter-spacing: 1px;
          font-size: 18px;
          @media (min-width: $screen-md-min) {
            font-size: 36px;
          }
        }
        .content {
          margin-left: 70px;
          @media (min-width: $screen-md-min) {
            margin-left: 120px;
          }
        }
      }
    }
    .details-container {
      padding-bottom: 20px;
      @media (min-width: $screen-md-min) {
        margin: -60px auto 0;
        padding-bottom: 45px;
      }
      .presents {
        display: none;
        @media (min-width: $screen-md-min) {
          display: block;
        }
      }
      &.page {
        padding-bottom: 0;
        @media (min-width: $screen-md-min) {
          padding-bottom: 45px;
        }
        .featured-header,
        .featured-footer {
          display: none;
          @media (min-width: $screen-md-min) {
            display: block;
          }
        }
      }
    }
    .details {
      position: relative;
      background-color: $white-smooth;
      box-shadow: 0 0 5px lighten($gray-light, 25%);
      text-align: center;
      z-index: 1;
      @media (min-width: $screen-md-min) {
        padding: 40px;
        text-align: left;
      }
      .anchor {
        display: block;
        position: relative;
        top: -120px;
        visibility: hidden;
      }
      .error {
        text-align: center;
        color: $blue;
        margin: 0;
        padding: 90px 10px;
        @media (min-width: $screen-md-min) {
          padding: 60px 0;
        }
      }
      .info {
        position: relative;
        padding: 30px 15px;
        @media (min-width: $screen-md-min) {
          padding: 60px 15px;
          margin-bottom: 10px;
        }
        h3 {
          @extend .md;
          color: $blue;
          font-size: 18px;
          letter-spacing: 1px;
          margin: 2px 0 20px;
          @media (min-width: $screen-md-min) {
            font-size: 22px;
            text-align: right;
          }
        }
        h4 {
          @extend .ultlt;
          color: $blue;
          font-size: 14px;
          letter-spacing: 1px;
          margin: 0;
          @media (min-width: $screen-md-min) {
            font-size: 22px;
            text-align: right;
          }
        }
        .col-md-5 {
          @media (min-width: $screen-md-min) {
            padding-right: 50px;
          }
        }
        .col-md-7 {
          @media (min-width: $screen-md-min) {
            -webkit-column-count: 2;
            -moz-column-count: 2;
            column-count: 2;
            -webkit-column-gap: 40px;
            -moz-column-gap: 40px;
            column-gap: 40px;
          }
          @media (min-width: $screen-lg-min) {
            -webkit-column-gap: 60px;
            -moz-column-gap: 60px;
            column-gap: 60px;
          }
          p {
            margin: 0;
          }
        }
        a {
          display: none;
          color: $white;
          background-color: #6f7983;
          @extend .bdcn;
          font-size: 12px;
          padding: 10px 66px 7px;
          position: absolute;
          right: 56px;
          bottom: 24px;
          @media (min-width: $screen-md-min) {
            display: inline-block;
          }
          @media (min-width: $screen-lg-min) {
            right: 106px;
          }
        }
      }
      .form {
        text-align: left;
        background-color: $white;
        padding: 10px 0 0;
        position: relative;
        @media (min-width: $screen-md-min) {
          padding: 20px 25px;
        }
        label {
          @extend .bdcn;
          letter-spacing: 1px;
          color: $blue;
          font-size: 12px;
          margin: 10px 0 5px;
        }
        .input-group {
          > input {
            border-right: 0;
          }
        }
        .row {
          > div {
            padding-right: 25px;
            padding-left: 25px;
          }
        }
        .book-btn {
          @media (min-width: $screen-md-min) {
            position: absolute;
            top: 30px;
            right: 25px;
            padding: 24px 50px 20px;
          }
        }
      }
      .apartments {
        margin: 0 10px;
        .nav {
          text-align: left;
          border: 0;
          li {
            float: none;
            display: inline-block;
            vertical-align: bottom;
            a {
              @extend .bdcn;
              color: $blue;
              border-top: 1px solid #dee1e3;
              border-left: 1px solid #dee1e3;
              border-right: 1px solid #dee1e3;
              font-size: 13px;
              @media (min-width: $screen-sm-min) {
                font-size: 15px;
              }
            }
            &.active {
              a {
                background-color: $blue;
                color: $white;
                border-top: 1px solid $blue;
                border-left: 1px solid $blue;
                border-right: 1px solid $blue;
                &:hover {
                  background-color: $blue-hover;
                }
              }
            }
          }
        }
        > .nav {
          li {
            width: 120px;
            @media (min-width: $screen-sm-min) {
              width: 200px;
            }
            @media (min-width: $screen-md-min) {
              width: auto;
            }
            a {
              @media (min-width: $screen-md-min) {
                padding: 20px 15px;
              }
            }
          }
        }
        .data {
          @media (min-width: $screen-sm-min) {
            margin-top: 20px;
          }
          > .tab-pane {
            > .row {
              position: relative;
              > div {
                position: static;
                &:first-of-type {
                  @media (min-width: $screen-sm-min) {
                    display: none;
                  }
                }
                &:last-of-type {
                  display: none;
                  @media (min-width: $screen-sm-min) {
                    display: block;
                  }
                }
              }
            }
          }
        }
        .swiper-container {
          width: 100%;
          height: 200px;
          margin: 10px 0;
          @media (min-width: $screen-md-min) {
            height: 350px
          }
          @media (min-width: $screen-lg-min) {
            height: 456px
          }
          .swiper-pagination {
            display: none;
            margin: 0;
          }
          .img {
            width: 100%;
            height: 100%;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
          }
        }
        .description {
          > .nav {
            > li {
              > a {
                padding: 15px 15px 12px;
              }
            }
          }
          .tab-content {
            background-color: $white;
            padding: 20px 10px 18px;
            margin-bottom: 10px;
            @media (min-width: $screen-sm-min) {
              position: absolute;
              top: 43px;
              bottom: 0;
              width: calc(50% - 30px);
              padding: 25px;
              overflow-y: auto;
              @media (min-width: $screen-md-min) {
                top: 50px;
              }
            }
            @media (min-width: $screen-md-min) {
              width: calc(41.6666666667% - 30px);
            }
             @media (min-width: $screen-lg-min) {
              width: calc(33.3333333333% - 30px);
            }
            .equipment {
              text-align: left;
              figure {
                width: 30px;
                text-align: center;
                display: inline-block;
                vertical-align: middle;
                margin-right: 10px;
              }
              .col-xs-12 {
                margin-bottom: 6px;
                @media (min-width: $screen-md-min) {
                  margin-bottom: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}