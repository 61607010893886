
// Page not Found
main.page-not-found {
  display: table;
  background-image: url(#{$img-path}/page-not-found.jpg);
  > div {
    position: relative;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    padding: 40px 0;
    @media (min-width: $screen-md-min) {
      padding: 0;
    }
  }
  h1 {
    color: $white;
    text-transform: uppercase;
    margin-bottom: 40px;
    @media (min-width: $screen-md-min) {
      margin-bottom: 80px;
    }
  }
  a {
    @extend .bdcn;
    color: $white;
    text-transform: uppercase;
    font-size: 14px;
    padding: 24px 84px 20px;
    border: 1px solid $white;
    display: inline-block;
  }
}