
// Utils
.mdcn {
  font-family: 'HelveticaNeueLTStd-MdCn', sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.bd {
  font-family: 'HelveticaNeueLTStd-Bd', sans-serif;
  text-transform: uppercase;
}

.ultlt {
  font-family: 'HelveticaNeueLTStd-UltLt', sans-serif;
  text-transform: uppercase;
}

.bdcn {
  font-family: 'HelveticaNeueLTStd-BdCn', sans-serif;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.hv {
  font-family: 'HelveticaNeueLTStd-Hv', sans-serif;
  letter-spacing: 2px;
}

.thcn {
  font-family: 'HelveticaNeueLTStd-ThCn', sans-serif;
  letter-spacing: 3px;
}

.md {
  font-family: 'HelveticaNeueLTStd-Md', sans-serif;
  text-transform: uppercase;
}

.yellowtail {
  font-family: 'Yellowtail', sans-serif;
}

.mosaic-bg {
  background-image: url(#{$img-path}/mosaic.jpg);
}

.bg {
  background-image: url(#{$img-path}/bg.jpg);
  background-size: cover;
}

.swiper-button-next {
  transform: rotate(180deg);
  right: 5%;
  @media (min-width: $screen-md-min) {
    right: 6%;
  }
}

.swiper-button-prev {
  left: 5%;
  @media (min-width: $screen-md-min) {
    right: 6%;
  }
}

.swiper-button-next, .swiper-button-prev {
  background-image: none;
  width: auto;
  height: auto;
  width: 30px;
  height: 30px;
  > div {
    position: relative;
    height: 100%;
    width: 100%;
    > div {
      position: relative;
      background-color: $blue;
      opacity: 0.6;
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
  img {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
  }
}

.mask {
  background-size: cover;
  background-position: center;
  position: relative;
  width: 100%;
  @media (min-width: $screen-md-min) {
    height: 490px;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $slider-bg;
  }
}

.featured-header {
  position: relative;
  > div {
    &:nth-child(1) {
      width: 250px;
      background: #f4f5f6;
      height: 10px;
      display: block;
      margin: 0 auto;
      box-shadow: 0 0 5px lighten($gray-light, 25%);
      transform: rotate(-2deg);
      position: absolute;
      right: 15px;
      top: -4px;
    }
    &:nth-child(2) {
      position: relative;
      z-index: 0;
      width: calc(100% - 16px);
      background: $white-smooth;
      height: 10px;
      display: block;
      margin: 0 auto;
      box-shadow: 0 0 5px lighten($gray-light, 25%);
    }
  }
}
.featured-footer {
  position: relative;
  @media (min-width: $screen-md-min) {
    margin-bottom: 0;
  }
  > div {
    &:nth-child(1) {
      width: 200px;
      background: #f4f5f6;
      height: 10px;
      display: block;
      margin: 0 auto;
      box-shadow: 0 0 5px lighten($gray-light, 25%);
      transform: rotate(-93deg);
      position: absolute;
      right: -98px;
      bottom: 120px;
    }
    &:nth-child(2) {
      position: relative;
      z-index: 0;
      width: calc(100% - 16px);
      background: $white-smooth;
      height: 10px;
      display: block;
      margin: 0 auto;
      box-shadow: 0 0 5px lighten($gray-light, 25%);
    }
  }
}

.dropdown-menu {
  margin-top: 64px;
  @media (min-width: $screen-md-min) {
    margin-top: 119px;
  }
}

.modal {
  .modal-dialog {
    @media (min-width: $screen-md-min) {
      width: 760px;
    }
  }
  .modal-content {
    position: relative;
    z-index: 1;
    border-radius: 0;
    border: 0;
    box-shadow: 0 0 5px lighten($gray-light, 25%);
    color: $blue;
    min-height: 220px;
  }
  .modal-header {
    border: 0;
    padding-bottom: 0;
    button {
      @extend .bd;
      font-size: 12px;
      color: $beige;
      opacity: 1;
      span {
        font-size: 16px;
      }
    }
  }
  .modal-body {
    padding: 15px 70px 60px;
  }
}

.label-img {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  @media (min-width: $screen-md-min) {
    max-width: 100%;
    display: block;
  }
}
.dropdown-backdrop {
  display: none;
}