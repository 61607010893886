
// Promotions
main.promotions {
  section.page {
    @extend .mosaic-bg;
    .mask {
      background-size: 0;
      text-align: center;
      margin-bottom: 20px;
      @media (min-width: $screen-md-min) {
        background-size: cover;
        display: table;
        margin-bottom: 45px;
      }
      &:before {
        display: none;
        @media (min-width: $screen-md-min) {
          display: block;
        }
      }
      .center {
        @extend .bg;
        @media (min-width: $screen-md-min) {
          background: none;
          position: relative;
          display: table-cell;
          vertical-align: middle;
        }
        .container {
          @media (min-width: $screen-md-min) {
            width: 500px;
          }
        }
        .text {
          display: none;
          color: $white;
          line-height: 26px;
          @media (min-width: $screen-md-min) {
            display: block;
          }
        }
      }
    }
    h1 {
      color: $blue;
      text-transform: uppercase;
      margin: 0;
      text-align: center;
      padding: 23px 0 22px;
      @media (min-width: $screen-md-min) {
        color: $white;
        margin: 0 0 60px;
        padding: 0;
      }
    }
    .book-btn {
      @media (min-width: $screen-md-min) {
        display: none;
        font-size: 12px;
      }
    }
    .promotion-container {
      margin-bottom: 45px;
      @media (min-width: $screen-md-min) {
        margin-bottom: 90px;
      }
    }
    .promotion {
      padding: 5px 5px 13px 5px;
      position: relative;
      z-index: 2;
      background-color: $white-smooth;
      box-shadow: 0 0 5px lighten($gray-light, 25%);
      text-align: center;
      @media (min-width: $screen-md-min) {
        text-align: left;
        padding: 20px;
      }
      img {
        height: 200px;
        margin: 30px auto;
        display: block;
      }
      .row {
        > div {
          &:nth-child(2) {
            @media (min-width: $screen-md-min) {
              padding: 0 55px 0 85px;
            }
          }
        }
      }
      .title {
        @extend .bd;
        color: $blue;
        margin: 20px 5px;
        @media (min-width: $screen-md-min) {
          margin: 45px 0 40px;
          font-size: 22px;
        }
      }
      .text {
        width: 72%;
        margin: auto;
        @media (min-width: $screen-md-min) {
          width: 100%;
        }
      }
      a {
        @extend .bdcn;
        border: 1px solid $blue;
        padding: 14px 12px 10px;
        display: inline-block;
        margin-top: 13px;
        &:hover {
          color: $white;
          background-color: $white-hover;
        }
        @media (min-width: $screen-md-min) {
          padding: 14px 32px 10px;
          margin-top: 35px;
        }
      }
    }
  }
}