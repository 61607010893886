
// Gallery
main.gallery {
  @extend .bg;
  section.slider {
    text-align: center;
    .mask {
      display: none;
      @media (min-width: $screen-md-min) {
        display: block;
      }
    }
    .frame {
      position: relative;
      display: inline-block;
      margin: 10px 10px 30px 10px;
      box-shadow: 0px 34px 80px $gray-light;
      max-width: calc(100% - 20px); // Firefox hack
      @media (min-width: $screen-md-min) {
        margin-top: -50%;
      }
      > img {
        position: relative;
        z-index: 2;
        pointer-events: none;
      }
      .swiper-container {
        position: absolute;
        top: 10px;
        bottom: 10px;
        left: 10px;
        right: 10px;
        background-color: #f2f2f2;
        .img {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          @media (min-width: $screen-md-min) {
            top: 37px;
            bottom: 38px;
            right: 40px;
            left: 40px;
          }
        }
      }
      .swiper-pagination {
        display: none;
      }
    }
  }
  section.info {
    text-align: center;
    h1 {
      color: $blue;
      text-transform: uppercase;
      @media (min-width: $screen-md-min) {
        position: absolute;
        color: $white;
        top: -790px;
        left: 15px;
        right: 15px;
        width: 90%;
        margin: auto;
      }
    }
    .text {
      margin: 20px 0 45px;
      @media (min-width: $screen-md-min) {
        margin: 55px 200px 200px;
      }
      p {
        margin: 0;
      }
    }
    .container {
      position: relative;
    }
    .book-btn {
      @media (min-width: $screen-md-min) {
        display: none;
      }
    }
  }
}
