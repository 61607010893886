
// Npm
@import "node_modules/font-awesome/scss/font-awesome";

// Fonts
@font-face {
  font-family: 'HelveticaNeueLTStd-Lt';
  src: url('../../fonts/HelveticaNeueLTStd-Lt.eot?#iefix') format('embedded-opentype'),  url('../../fonts/HelveticaNeueLTStd-Lt.otf')  format('opentype'),
       url('../../fonts/HelveticaNeueLTStd-Lt.woff') format('woff'), url('../../fonts/HelveticaNeueLTStd-Lt.ttf')  format('truetype'), url('../../fonts/HelveticaNeueLTStd-Lt.svg#HelveticaNeueLTStd-Lt') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueLTStd-MdCn';
  src: url('../../fonts/HelveticaNeueLTStd-MdCn.eot?#iefix') format('embedded-opentype'),  url('../../fonts/HelveticaNeueLTStd-MdCn.otf')  format('opentype'),
       url('../../fonts/HelveticaNeueLTStd-MdCn.woff') format('woff'), url('../../fonts/HelveticaNeueLTStd-MdCn.ttf')  format('truetype'), url('../../fonts/HelveticaNeueLTStd-MdCn.svg#HelveticaNeueLTStd-MdCn') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueLTStd-UltLt';
  src: url('../../fonts/HelveticaNeueLTStd-UltLt.eot?#iefix') format('embedded-opentype'),  url('../../fonts/HelveticaNeueLTStd-UltLt.otf')  format('opentype'),
       url('../../fonts/HelveticaNeueLTStd-UltLt.woff') format('woff'), url('../../fonts/HelveticaNeueLTStd-UltLt.ttf')  format('truetype'), url('../../fonts/HelveticaNeueLTStd-UltLt.svg#HelveticaNeueLTStd-UltLt') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueLTStd-Bd';
  src: url('../../fonts/HelveticaNeueLTStd-Bd.eot?#iefix') format('embedded-opentype'),  url('../../fonts/HelveticaNeueLTStd-Bd.otf')  format('opentype'),
       url('../../fonts/HelveticaNeueLTStd-Bd.woff') format('woff'), url('../../fonts/HelveticaNeueLTStd-Bd.ttf')  format('truetype'), url('../../fonts/HelveticaNeueLTStd-Bd.svg#HelveticaNeueLTStd-Bd') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueLTStd-BdCn';
  src: url('../../fonts/HelveticaNeueLTStd-BdCn.eot?#iefix') format('embedded-opentype'),  url('../../fonts/HelveticaNeueLTStd-BdCn.otf')  format('opentype'),
       url('../../fonts/HelveticaNeueLTStd-BdCn.woff') format('woff'), url('../../fonts/HelveticaNeueLTStd-BdCn.ttf')  format('truetype'), url('../../fonts/HelveticaNeueLTStd-BdCn.svg#HelveticaNeueLTStd-BdCn') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueLTStd-Hv';
  src: url('../../fonts/HelveticaNeueLTStd-Hv.eot?#iefix') format('embedded-opentype'),  url('../../fonts/HelveticaNeueLTStd-Hv.otf')  format('opentype'),
       url('../../fonts/HelveticaNeueLTStd-Hv.woff') format('woff'), url('../../fonts/HelveticaNeueLTStd-Hv.ttf')  format('truetype'), url('../../fonts/HelveticaNeueLTStd-Hv.svg#HelveticaNeueLTStd-Hv') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Yellowtail';
  src: url('../../fonts/Yellowtail.eot?#iefix') format('embedded-opentype'),  url('../../fonts/Yellowtail.otf')  format('opentype'),
       url('../../fonts/Yellowtail.woff') format('woff'), url('../../fonts/Yellowtail.ttf')  format('truetype'), url('../../fonts/Yellowtail.svg#Yellowtail') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueLTStd-ThCn';
  src: url('../../fonts/HelveticaNeueLTStd-ThCn.eot?#iefix') format('embedded-opentype'),  url('../../fonts/HelveticaNeueLTStd-ThCn.otf')  format('opentype'),
       url('../../fonts/HelveticaNeueLTStd-ThCn.woff') format('woff'), url('../../fonts/HelveticaNeueLTStd-ThCn.ttf')  format('truetype'), url('../../fonts/HelveticaNeueLTStd-ThCn.svg#HelveticaNeueLTStd-ThCn') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueLTStd-Md';
  src: url('../../fonts/HelveticaNeueLTStd-Md.eot?#iefix') format('embedded-opentype'),  url('../../fonts/HelveticaNeueLTStd-Md.otf')  format('opentype'),
       url('../../fonts/HelveticaNeueLTStd-Md.woff') format('woff'), url('../../fonts/HelveticaNeueLTStd-Md.ttf')  format('truetype'), url('../../fonts/HelveticaNeueLTStd-Md.svg#HelveticaNeueLTStd-Md') format('svg');
  font-weight: normal;
  font-style: normal;
}
