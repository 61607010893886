
// Base rules
body {
  overflow-x: hidden;
}

a {
  @include transition(all $transition-time);
  &:hover {
    text-decoration: none;
  }
}

ul {
  @extend .list-unstyled;
}

textarea {
  resize: vertical;
}

img {
  max-width: 100%;
  height: auto;
}

h1 {
  font-size: 30px;
  @media (min-width: $screen-md-min) {
    font-size: $font-size-h1;
  }
}

input, textarea {
  border-color: #b0b0b2 !important;
  box-shadow: none !important;
}