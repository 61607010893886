
// Path
$img-path: '../../img';

// Transitions
$transition-time: 0.2s;

// Colors
$white: #FFF;
$white-smooth: #f4f5f6;
$blue: #445262;
$beige: #c0b7ae;
$beige-dark: #c1b6ae;
$red: #e67477;
$slider-bg: rgba(8, 17, 27, 0.72);
$blue-hover: #2f373f;
$white-hover: #262626;

// Fontawesome
$fa-font-path: "../../fonts/font-awesome";

// Bootstrap
$brand-primary: $blue;

$font-size-h1: 42px;

$text-color: #000;

$font-family-sans-serif: 'HelveticaNeueLTStd-Lt', sans-serif;

$navbar-height:                      64px;
$navbar-default-toggle-border-color: none;
$navbar-default-toggle-icon-bar-bg:  $blue;
$navbar-default-border:              none;
$navbar-default-toggle-hover-bg:     none;
$navbar-default-link-color:          $blue;
$navbar-default-link-active-bg:      transparent;

$grid-float-breakpoint: 992px;

$border-radius-base: 0;
