
// Home
main.home {
  section.swiper-container {
    width: 100%;
    @media (min-width: $screen-md-min) {
      box-shadow: 0 0 10px $gray-dark;
    }
    h2 {
      @extend .ultlt;
      font-size: 30px;
      margin-bottom: 25px;
      @media (min-width: $screen-md-min) {
        font-size: 72px;
      }
    }
    .swiper-button-next {
      display: none;
    }
    .swiper-button-prev {
      display: none;
    }
    .swiper-pagination-bullet {
      background-color: #e8e8e8;
      opacity: 1;
      @media (min-width: $screen-md-min) {
        width: 14px;
        height: 14px;
      }
    }
    .swiper-pagination-bullet-active {
      background-color: $beige;
    }
    .img {
      position: relative;
      width: 100%;
      height: 211px;
      background-size: cover;
      background-position: center;
      &:before {
        background-color: rgba(0, 0, 0, 0.4);
      }
      @media (min-width: $screen-sm-min) {
        height: 360px;
      }
      @media (min-width: $screen-md-min) {
        height: 555px;
      }
    }
    .text {
      font-size: 16px;
      p {
        margin: 0;
      }
    }
    .content {
      color: $blue;
      text-align: center;
      @media (min-width: $screen-md-min) {
        color: $white;
        text-align: left;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        width: 974px;
        margin: 0 auto;
        @media (min-width: $screen-lg-min) {
          width: 1174px;
        }
        &:before {
          content: ' ';
          display: inline-block;
          height: 100%;
          vertical-align: middle;
        }
      }
      > div {
        @media (min-width: $screen-md-min) {
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
    .container {
      position: relative;
      margin: 20px auto 70px;
      padding: 0px 25px;
      @media (min-width: $screen-md-min) {
        margin: 0 auto;
        padding: 0 30% 0 15px;
      }
      @media (min-width: $screen-lg-min) {
        padding: 0 50% 0 15px;
      }
    }
  }
  section.price {
    text-align: center;
    @extend .mosaic-bg;
    @media (min-width: $screen-md-min) {
      background-image: none;
    }
    .container {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
      position: relative;
      @media (min-width: $screen-md-min) {
        width: 970px;
        padding-left: 15px;
        padding-right: 15px;
      }
      .info-container {
        @media (min-width: $screen-md-min) {
          position: absolute;
          right: 15px;
          bottom: 0;
          z-index: 1;
        }
      }
      .info {
        background-color: rgba(8, 17, 27, 0.6);
        padding: 20px 0px 10px;
        @media (min-width: $screen-md-min) {
          padding: 20px 20px 10px;
        }
        @media (min-width: $screen-lg-min) {
          padding: 20px 50px 10px;
        }
      }
    }
    h5 {
      color: $white;
      text-transform: uppercase;
      letter-spacing: 1px;
      margin-bottom: 25px;
    }
    .price {
      @extend .bd;
      color: $beige;
      font-size: 58px;
      line-height: 40px;
    }
    a {
      margin-bottom: 0;
      font-size: 17px;
      padding: 20px 0 16px;
      letter-spacing: 3px;
    }
  }
  section.featureds {
    padding: 32px 0 0;
    @extend .mosaic-bg;
    text-align: center;
    @media (min-width: $screen-md-min) {
      text-align: left;
      padding: 32px 0 160px;
    }
    .col-xs-12 {
      position: relative;
      z-index: 2;
      @media (min-width: $screen-md-min) {
        overflow-x: hidden;
      }
      .featured-footer {
        margin-bottom: 32px;
        @media (min-width: $screen-md-min) {
          margin-bottom: 0;
        }
      }
      &:first-of-type {
        @media (min-width: $screen-md-min) {
          padding-top: 8px;
          .featured-header {
            display: block;
          }
        }
      }
      &:last-of-type {
        @media (min-width: $screen-md-min) {
          .featured-footer {
            display: block;
          }
        }
      }
      .featured-header {
        @media (min-width: $screen-md-min) {
          display: none;
        }
      }
      .featured-footer {
        @media (min-width: $screen-md-min) {
          display: none;
        }
      }
    }
    .featured {
      position: relative;
      background-color: $white-smooth;
      box-shadow: 0 0 5px lighten($gray-light, 25%);
      z-index: 1;
      padding: 20px 0 22px;
      @media (min-width: $screen-md-min) {
        padding: 40px 0;
      }
      h2 {
        @extend .ultlt;
        font-size: $font-size-h1 / 2;
        color: $beige;
        padding-bottom: 15px;
        @media (min-width: $screen-md-min) {
          font-size: $font-size-h1;
        }
      }
      .title-container {
        @media (min-width: $screen-md-min) {
          display: inline-block;
        }
        > div {
          border-top: 1px solid $blue;
          width: 60%;
          margin: 0 auto;
          @media (min-width: $screen-md-min) {
            width: 100%;
          }
        }
      }
      .clip {
        position: absolute;
        top: 0;
        right: -18px;
      }
      .text {
        margin: 17px 0 15px;
        overflow: hidden;
        @media (min-width: $screen-md-min) {
          margin: 17px 0 40px;
        }
        .TA_cdsratingsonlywide {
          margin: 30px auto;
          display: none;
          @media (min-width: $screen-sm-min) {
            display: inline-block;
          }
          .cdsROW {
            background-color: transparent;
            width: 468px !important;
          }
          .cdsComponent {
            @media (min-width: $screen-lg-min) {
              max-width: 150px !important;
            }
          }
          .logo {
            padding-left: 0;
          }
        }
      }
      .view-btn {
        @extend .bdcn;
        font-size: 15px;
        color: $text-color;
        border: 1px solid $text-color;
        padding: 11px 50px 8px;
        display: inline-block;
        &:hover {
          color: $white;
          background-color: $white-hover;
        }
        @media (min-width: $screen-md-min) {
          padding: 22px 100px 19px;
        }
      }
      .col-md-6 {
        @media (min-width: $screen-md-min) {
          padding: 0 30px;
        }
        &.info {
          @media (min-width: $screen-md-min) {
            padding: 0 60px;
          }
        }
      }
    }
  }
  section.contact {
    text-transform: uppercase;
    text-align: center;
    color: $blue;
    background-color: $beige-dark;
    letter-spacing: 2px;
    z-index: 1;
    @media (min-width: $screen-md-min) {
      background-color: transparent;
      position: absolute;
      left: 0;
      right: 0;
      margin-top: -170px;
    }
    a {
      @extend .hv;
      color: $blue;
      text-decoration: underline;
    }
    .container {
      > div {
        padding: 40px 0;
        @media (min-width: $screen-md-min) {
          background-color: $beige-dark;
          font-size: 18px;
          padding: 45px 0 40px;
        }
        > div {
          @media (min-width: $screen-md-min) {
            display: inline-block;
          }
        }
      }
    }
  }
}