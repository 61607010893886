
// Header
header.main {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
  box-shadow: 0 0 10px $gray-dark;
  .navbar-header {
    position: relative;
    z-index: 1
  }
  .navbar {
    background-color: $white;
    margin-bottom: 0;
    border: 0;
    @media (min-width: $screen-md-min) {
      max-height: 119px;
    }
    .container {
      @media (min-width: $screen-md-min) {
        position: relative;
      }
      &:before {
        display: none;
        content: '';
        width: calc(100% - 219px);
        height: 1px;
        background-color: $blue;
        position: absolute;
        top: 42px;
        right: 15px;
        @media (min-width: $screen-md-min) {
          display: block;
        }
        @media (min-width: $screen-lg-min) {
          width: calc(100% - 223px);
        }
      }
    }
    .navbar-collapse {
      position: absolute;
      left: 0;
      right: 0;
      background-color: $white;
      @media (min-width: $screen-md-min) {
        position: relative;
        left: auto;
        right: auto;
        background-color: transparent;
         padding-right: 0;
      }
      &.in {
        overflow-x: hidden;
        margin-right: -30px;
        height: calc(100vh - 64px);
      }
      .container {
        @media (min-width: $screen-md-min) {
          padding: 0;
          width: 100%;
        }
        &:before {
          display: none;
        }
      }
      .book-btn-container {
        font-size: 15px;
        @extend .mdcn;
        @media (min-width: $screen-md-min) {
          position: absolute;
          top: -42px;
          right: 240px;
        }
        @media (min-width: $screen-lg-min) {
          position: relative;
          float: right;
          top: 0;
          right: 0;
        }
        a {
          color: $white;
          background-color: $blue;
          display: inline-block;
          padding: 11px 15px 9px;
          &:hover {
            background-color: $blue-hover;
          }
          @media (min-width: $screen-md-min) {
            padding: 13px 10px 9px;
          }
          @media (min-width: $screen-lg-min) {
            padding: 19px 23px 16px;
          }
        }
      }
    }
    .navbar-nav-container {
      height: 100vh;
      @media (min-width: $screen-md-min) {
        height: 77px;
        margin-top: 42px;
        padding-top: 14px;
      }
    }
    .langs {
      position: relative;
      margin-top: 10px;
      @media (min-width: $screen-md-min) {
        position: absolute;
        top: -32px;
        right: -15px;
        margin: 0;
      }
      a {
        @extend .mdcn;
      }
      .dropdown-menu {
        position: static;
        float: none;
        width: auto;
        margin-top: 0;
        background-color: transparent;
        border: 0;
        box-shadow: none;
        @media (min-width: $screen-md-min) {
          position: absolute;
          border: 1px solid $beige-dark;
          background-color: $white;
          padding: 0;
          min-width: 100px;
        }
      }
      .dropdown-toggle {
        font-size: 13px;
        color: #9d968f;
        @media (min-width: $screen-md-min) {
          display: inline-block;
          vertical-align: middle;
          padding: 0 7px;
          line-height: 0;
        }
        &:hover {
          color: #9d968f;
        }
        img {
          position: relative;
          top: -1px;
        }
      }
      &.open {
        .dropdown-toggle {
          color: #9d968f;
          background-color: transparent;
        }
        .dropdown-menu {
          margin-top: 0;
          box-shadow: none;
          border-color: $beige;
          padding: 0;
          min-width: 100px;
          li {
            border-top: 1px solid $beige;
            &:first-of-type {
              border: 0;
            }
          }
          a {
            @extend .mdcn;
            color: #9d968f;
            padding: 10px 20px 5px;
          }
        }
      }
    }
    .navbar-nav {
      font-size: 0;
      margin-bottom: 0;
      @media (min-width: $screen-md-min) {
        display: table;
        width: calc(100% - 168px);
        height: 100%;
      }
      @media (min-width: $screen-lg-min) {
        display: block;
        float: none;
        width: 100%;
      }
      > li {
        position: relative;
        font-size: 13px;
        @media (min-width: $screen-md-min) {
          width: calc(100% / 7);
          height: 60px;
          text-align: center;
          display: table-cell;
          vertical-align: middle;
          float: none;
        }
        @media (min-width: $screen-lg-min) {
          width: calc(100% / 9);
        }
        &.active {
          &:after {
            content: '';
            background-color: $blue;
            width: calc(100% - 14px);
            height: 5px;
            position: absolute;
            display: block;
            bottom: 2px;
            left: 0;
            right: 0;
            margin: 0 auto;
            @media (min-width: $screen-md-min) {
              height: 13px;
              bottom: -1px;
            }
          }
        }
        > a {
          @extend .mdcn;
          @media (min-width: $screen-md-min) {
            padding: 0;
          }
        }
      }
    }
  }
  .navbar-toggle {
    float: none;
    .icon-bar {
      height: 1px;
      + .icon-bar {
        margin-top: 6px;
      }
    }
  }
  .navbar-brand {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 0;
    margin: auto;
    width: 100px;
    height: 43px;
    @media (min-width: $screen-md-min) {
      position: relative;
      padding: 21.5px 15px;
      width: auto;
      height: auto;
    }
    img {
      width: 100px;
      height: 43px;
      @media (min-width: $screen-md-min) {
        width: auto;
        height: auto;
      }
    }
  }
  .social {
    margin: 10px 15px 10px 0;
    @media (min-width: $screen-md-min) {
      display: inline-block;
      vertical-align: middle;
      margin: 0 15px;
    }
    a {
      display: inline-block;
      margin: 0 4px;
      &:first-of-type {
        margin-left: 0;
      }
    }
  }
}

main {
  margin-top: 64px;
  @media (min-width: $screen-md-min) {
    margin-top: 119px;
  }
  .book-btn {
    position: relative;
    text-align: center;
    @extend .bdcn;
    color: $white;
    background-color: $blue;
    display: block;
    font-size: 15px;
    padding: 29px 0 25px;
    margin-bottom: 12px;
    &:hover {
      background-color: $blue-hover;
    }
    &.mosaic {
      &:after {
        content: '';
        display: block;
        @extend .mosaic-bg;
        position: absolute;
        top: 75px;
        width: 100%;
        height: 12px;
      }
    }
  }
  .container {
    padding-left: 7px;
    padding-right: 7px;
    @media (min-width: $screen-sm-min) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

footer.main {
  color: $white;
  background-color: $blue;
  text-align: center;
  padding-bottom: 20px;
  @media (min-width: $screen-md-min) {
    text-align: left;
    padding: 30px 0 60px;
  }
  h5 {
    @extend .bd;
    letter-spacing: 3px;
    display: inline-block;
    margin: 30px 0 17px;
  }
  .line {
    display: inline-block;
    border-bottom: 1px solid $white;
    margin-bottom: 29px;
    @media (min-width: $screen-md-min) {
      width: 160px;
    }
  }
  .logo {
    display: none;
    @media (min-width: $screen-md-min) {
      display: block;
    }
    a {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 15px;
      margin: auto;
      height: 91px;
    }
  }
  .sitemap {
    display: none;
    @media (min-width: $screen-md-min) {
      display: block;
    }
    a {
      color: $white;
    }
  }
  .contact {
    .phone {
      @extend .bd;
      letter-spacing: 1px;
      color: $white;
    }
    .email {
      color: $white;
      margin: 20px 0;
      display: inline-block;
    }
    .address {
      @media (min-width: $screen-md-min) {
        width: 85%;
      }
    }
  }
  .social {
    a {
      margin: 0 7px;
      @media (min-width: $screen-md-min) {
        &:first-of-type {
          margin-left: 0;
        }
      }
    }
  }
  .row {
    @media (min-width: $screen-md-min) {
      display: table;
    }
    > div {
      @media (min-width: $screen-md-min) {
        display: table-cell;
        float: none;
      }
    }
  }
}